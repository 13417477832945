<template>
  <base-material-card
    icon="mdi-clipboard-text"
    :title="$t('menu.coinUsage.list')"
    class="px-5 py-3"
  >
    <!--    <base-add-button :permissions="permissions" :basePath="basePath" :title="$t('coinUsage.add')">-->
    <!--    </base-add-button>-->
    <div class="mt-2">{{ $t('coinUsage.viewTotal') }}: {{ meta.total }}</div>
    <v-simple-table v-if="items && items.length > 0">
      <thead>
        <tr>
          <th class="primary--text">No.</th>
          <th class="primary--text">
            {{ $t('coinUsage.actionType') }}
          </th>
          <th class="primary--text">
            {{ $t('coinUsage.description') }}
          </th>
          <th class="primary--text">
            {{ $t('coinUsage.coinsUsed') }}
          </th>
          <th class="primary--text">
            {{ $t('coinUsage.userName') }}
          </th>
          <th class="primary--text">
            {{ $t('coinUsage.userEmail') }}
          </th>
          <th class="primary--text">
            {{ $t('coinUsage.usedAt') }}
          </th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(item, index) in items" :key="`coin-usage-${index}`">
          <td>{{ index + 1 }}</td>
          <td>{{ item.coin_action_type_id }}</td>
          <td>{{ item.description }}</td>
          <td>{{ item.coins_used }}</td>
          <td>{{ item.user_name }}</td>
          <td>{{ item.user_email }}</td>
          <td>
            {{ item.used_at ? format(new Date(item.used_at), 'dd MMMM yyyy HH:mm') : '' }}
          </td>
        </tr>
      </tbody>
    </v-simple-table>
    <pagination
      :push-state="false"
      :meta="meta"
      @changePage="changePage"
      :maxVisibleButtons="5"
      v-if="meta"
    />
  </base-material-card>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import { format } from 'date-fns';
const Pagination = () => import('@/components/utils/fractal-pagination');

export default {
  mixins: [HelperMixin],
  props: ['items', 'meta', 'permissions'],
  components: {
    Pagination,
  },
  data() {
    return {
      format,
    };
  },
  computed: {
    ...mapState({
      basePath: (state) => state.coinUsage.basePath,
    }),
  },
  methods: {
    changePage(page) {
      this.$emit('changePage', page);
    },
  },
};
</script>
